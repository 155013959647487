/* global LEGACY_BUILD */
/* eslint-disable global-require */
if (LEGACY_BUILD) {
    require('core-js/stable');
    require('custom-event-polyfill');
    require('element-closest/browser');
    require('es6-promise/auto');
    require('intersection-observer');
}
/* eslint-enable global-require */
